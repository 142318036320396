import React from 'react';
import styled from 'styled-components';
import Section from '../../components/section';
import Avatar from '../../components/avatar';
import Divider from '../../components/divider';
import {
  HeadlineContent,
  SaraContent,
  // FedeContent,
  HarveyContent
} from '../../components/about/content';
import ImageSara from '../../components/image-sara';
// import ImageFede from '../../components/image-fede';
import ImageHarvey from '../../components/image-harvey';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Div = styled.div`
  background-image: linear-gradient(170deg, #FAE3D4, #FFF8F3);
`;
// -----------------------------------------------------------------------------
const Item = styled.div`
  max-width: 1050px;
`;
// -----------------------------------------------------------------------------
const imgStyle = { borderRadius: '50%', height: '100%' };
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const AboutDesktop = () => (
  <Div>
    <Section viewType="desktop">
      <div className="pt4" />
      <div className="pt4" />
      <HeadlineContent />
      <div className="py1" />
      <Divider style={{ maxWidth: '400px' }} className="mx-auto" />
      <div className="py2" />
      <Item className="flex align-center">
        <Avatar size="L" className="flex-none">
          <ImageSara style={imgStyle} />
        </Avatar>
        <div className="mx2" />
        <div className="mx1" />
        <div className="flex-auto">
          <SaraContent />
        </div>
      </Item>
      <div className="py1" />
      <Divider />
      <div className="py2" />
      <Item className="flex align-center">
        <Avatar size="L" className="flex-none">
          <ImageHarvey style={imgStyle} />
        </Avatar>
        <div className="mx2" />
        <div className="mx1" />
        <div className="flex-auto">
          <HarveyContent />
        </div>
      </Item>
      <div className="py3" />
    </Section>
  </Div>
);

export default AboutDesktop;
