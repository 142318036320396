import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// -----------------------------------------------------------------------------
// CONSTANTS:
// -----------------------------------------------------------------------------
const SMALL = 120;
const LARGE = 170;

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Circle = styled.div`
  border: 1px solid ${ ({ theme }) => theme.color.primary };
  border-radius: 50%;
  height: ${ ({ size }) => size === 'L' ? LARGE : SMALL }px;
  width: ${ ({ size }) => size === 'L' ? LARGE : SMALL }px;
  background-color: ${ ({ theme }) => theme.color.secondary };
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Avatar = ({ children, ...rest }) => (
  <Circle {...rest}>
    {children}
  </Circle>
);

Avatar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  size: PropTypes.oneOf(['S', 'L']).isRequired,
  // Plus all native button props
};

export default Avatar;
