import React from 'react';
import ErrorBoundary from '../components/error-boundary';
import Layout from '../components/layout';
import AboutMobile from '../mobile-sections/about';
import AboutDesktop from '../desktop-sections/about';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const AboutPage = ({ location }) => (
  <ErrorBoundary>
    <Layout location={location}>
      <div className="hide-for-large">
        <AboutMobile />
      </div>
      <div className="show-for-large">
        <AboutDesktop />
      </div>
    </Layout>
  </ErrorBoundary>
);

export default AboutPage;
