import React from 'react';
import styled from 'styled-components';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Title = styled.h1`
  font-family: 'Baloo', sans serif;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  color: ${ ({ theme }) => theme.color.primary };
  max-width: 400px;
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 40px;
    line-height: 40px;
  }
`;
// -----------------------------------------------------------------------------
const Name = styled.h3`
  font-family: 'Open Sans', sans serif;
  font-weight: 1000;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${ ({ theme }) => theme.color.secondary };
  margin: 0 0 10px 0;
  text-decoration: underline;
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 20px;
  }
`;
// -----------------------------------------------------------------------------
const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 18px;
  }
`;
// -----------------------------------------------------------------------------
// COMPONENTS:
// -----------------------------------------------------------------------------
// TODO: use Text comp
export const HeadlineContent = () => (
  <Title className="m0 mx-auto center">
    The People and Paws Behind Pet Digs
  </Title>
);

export const SaraContent = props => (
  <div {...props}>
    <Name>Sara- Founder and CEO</Name>
    <Description>
      Sara is an avid pet lover who travels a lot. She didn’t like the expense of sitters for her
      rescue cat, Harvey, nor did she want to put him through the stress of boarding. Plus,
      she felt guilty repeatedly asking friends to watch Harvey. So, Sara created Pet Digs so
      that people could exchange pet and home care for accommodations; a trustworthy,
      economic and fun pet care option that helps others in the process.
      <br /><br />
      Some of Sara’s likes include dog walking, volunteering as a horse groomer at a
      therapeutic riding center, snuggles from Harvey, blueberry pancakes and coastal hikes
      in the Bay Area. Dislikes include morning alarms, grapes with seeds, trimming Harvey’s
      nails and riding her bike up hills in San Francisco.
    </Description>
  </div>
);

export const FedeContent = props => (
  <div {...props}>
    <Name>Fede</Name>
    <Description>
      A serial entrepreneur who enjoys building, Fede creates things he loves whether he’s using wood, metal or coding. Having lived in several countries while speaking a handful of different languages, Fede’s joy of travel comes naturally. He also loves animals—his home in Buenos Aires even had a garden that sheltered pregnant cats and their kittens.
      <br /><br />
      Some of Fede’s likes include soccer, volleyball, ping pong, recycling, plants and taking care of friends’ pets when they are away on holiday. Dislikes include big cities, cell phones, cigarette smoke and overly-complicated solutions.
    </Description>
  </div>
);

export const HarveyContent = props => (
  <div {...props}>
    <Name>Harvey- Head of PR (Pet Relations)</Name>
    <Description>
      Harvey is a tuxedo-wearing feline
      who lives to lounge. He allows his parents, Sara and Eze, to stay in his home.
      Secretly, he is a goofball on the inside, but is usually much too proper to act up in front
      of company.
      <br /><br />
      Some of his likes include pranking his parents, knocking over his water bowl, following
      the red dot that moves, sunbathing, having his chin scratched and playing futbol.
      Dislikes include being brushed, vacuums, not being fed on time, lack of attention,
      and when a closed door prevents his entrance.
    </Description>
  </div>
);
