import React from 'react';
import styled from 'styled-components';
import Section from '../../components/section';
import Avatar from '../../components/avatar';
import Divider from '../../components/divider';
import {
  HeadlineContent,
  SaraContent,
  // FedeContent,
  HarveyContent
} from '../../components/about/content';
import ImageSara from '../../components/image-sara';
// import ImageFede from '../../components/image-fede';
import ImageHarvey from '../../components/image-harvey';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Div = styled.div`
  background-image: linear-gradient(170deg, #FAE3D4, #FFF8F3);
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const AboutMobile = () => (
  <Div>
    <Section viewType="mobile">
      <div className="pt4" />
      <div className="pt4" />
      <HeadlineContent />
      <div className="py1" />
      <Divider style={{ maxWidth: '400px' }} className="mx-auto" />
      <div className="py2" />
      <div className="clearfix">
        <Avatar size="S" className="left mr2">
          <ImageSara style={{ borderRadius: '50%', height: '100%' }} />
        </Avatar>
        <SaraContent className="pt3" />
      </div>
      <div className="py1" />
      <Divider />
      <div className="py2" />
      <div className="clearfix">
        <Avatar size="S" className="left mr2">
          <ImageHarvey style={{ borderRadius: '50%', height: '100%' }} />
        </Avatar>
        <HarveyContent className="pt3" />
      </div>
      <div className="py3" />
    </Section>
  </Div>
);

export default AboutMobile;
